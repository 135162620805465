


import { Component, Vue, Mixins } from "vue-property-decorator";
import { BASE_API_URL } from "@/config";
import striptags from "striptags";
import Axios from "axios";

import { HollowDotsSpinner } from "epic-spinners";
import stores from "@/store/store";
const store: any = stores;
@Component({
  components: { HollowDotsSpinner }
})
export default class WeHelpList extends Vue {
  public wemloSupportData: any = {};
  public wemloSupports = [];
  public showThankyouComponent = false;
  public isMailSent = false;
  public hollowLoader = false;
  public weHelpTeamResponseMsg = "";
  public message = "";
  public async getWeHelpCollectionData() {
    try {
      this.hollowLoader = true;
      let body = {
        userId: this.$store.state.sessionObject.userId,
        userType: this.$store.state.sessionObject.type
      };

      let response = await Axios.post(
        BASE_API_URL + "broker/getWeHelpCollectionData",
        body);

      this.wemloSupports = response.data.wemloSupport;
      this.hollowLoader = false;
    } catch (error) {
      console.log(error);
      this.hollowLoader = false;
    }
  }

  public showFullMessage(message) {
    this.message = message;
    this.$modal.show("showFullMessage");
  }

  public removeTag(message) {
    let stripedMessage = striptags(message, [], " ");
    let wehelpMessage = stripedMessage.replace(/\s\s+/g, " ");
     wehelpMessage=wehelpMessage.replace(/&amp;/g, "&");  
    return wehelpMessage;
  }

  public async getWeHelpTeamResponseMsg(id) {
    try {
      let response = await Axios.post(
        BASE_API_URL + "broker/getWeHelpTeamResponseMsg",
        { id: id });

      this.weHelpTeamResponseMsg = response.data.responseMessage;
      this.$modal.show("ViewResponseMsgModal");
    } catch (error) {
      console.log(error);
    }
  }
  mounted() {
    this.getWeHelpCollectionData();
  }
}
