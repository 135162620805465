


import { Component, Vue, Mixins } from "vue-property-decorator";
import { BASE_API_URL } from "@/config";
import Axios from "axios";
import striptags from "striptags";

import { HollowDotsSpinner } from "epic-spinners";
import stores from "@/store/store";
const store: any = stores;
@Component({
  components: { HollowDotsSpinner }
})
export default class WeHelpList extends Vue {
  public wemloSupportData: any = {};
  public wemloSupports = [];
  public showThankyouComponent = false;
  public isMailSent = false;
  public wehelpLoader = false;
  public weHelpTeamResponse = "";
  public async getWeHelpData() {
    try {
      this.wehelpLoader =true
      let body = {
        userId: this.$store.state.sessionObject.userId,
        userType: this.$store.state.sessionObject.type
      };
      let response = await Axios.post(
        BASE_API_URL + "weHelp/getWeHelpData",
        body);
      response.data.wemloSupport.forEach(e => {
        let stripedMessage = striptags(e.description, [], " ");
        e.description = stripedMessage.replace(/\s\s+/g, " ");
      });
      this.wemloSupports = response.data.wemloSupport;
       this.wehelpLoader =false
    } catch (error) {
      console.log(error);
       this.wehelpLoader =false
    }
  }

  public async getWeHelpTeamResponse(id) {
    try {
      let response = await Axios.post(
        BASE_API_URL + "weHelp/getWeHelpTeamResponse",
        { id: id });
      // let stripedMessage = striptags(response.data.responseMessage, [], " ");
      // this.weHelpTeamResponse = stripedMessage.replace(/\s\s+/g, " ");
      this.weHelpTeamResponse = response.data.responseMessage;
      this.$modal.show("ViewResponse");
    } catch (error) {
      console.log(error);
      
    }
  }

  mounted() {
    this.getWeHelpData();
  }
}
